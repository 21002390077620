.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    text-align: center;
    display: block;
    width: 100%;
    cursor: pointer;
}

.Horizontal {

}

.NavigationItem a {
    text-decoration: none;
    width: inherit;
    color: inherit;

}

.NavigationItem div {
    color: white;
    background-color: rgba(var(--dark-green), 0.7);
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    font-size: 22px;
    margin: 0;
    font-family: Helvetica;
    font-weight: bold;
}

.NavigationItem div:hover,
.NavigationItem div:active,
.NavigationItem div.active  {
    color:  black;
    background-color: var(--light-green);
    box-shadow: 1px 1px 18px black;
}

@media (min-width: 500px) {
    .NavigationItem {
        margin: 0;
        box-sizing: border-box;
        display: flex;
        height: 100%; 

    }
    
    .NavigationItem div {
        height: 100%;
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
        background-color: var(--dark-green);
    }
    
    
}



