:root {
  --dark-green: #0b1c04;
  --dark-green-rgb: 11, 28, 4;
  --light-green: #8aba54;
  --gray: white;
  --dark-blue: #1a4859;
  --light-blue: #b0e2e8;
  --title-text: white;
  scroll-behavior: smooth;
}

.ContentSpacer {
  width: 100%;
  height: 2px;
  margin: auto;
  background-color: black;
}