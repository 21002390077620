.SlideDrawer {
    position: fixed;
    width: 100%;
    max-width: 100%;
    height: auto;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: rgba(var(--dark-green-rgb), 0.7);
    padding: 32px 16px;
    box-sizing: border-box;
    box-shadow: 1px 1px 25px 3px black;
    transition: transform 0.3s ease-out;
}

@media (min-width: 500px) {
    .SlideDrawer {
        display: none;
    }
}

.Open {
    transform: translateY(0);
}

.Close {
    transform: translateY(-115%);
}