.Section {
    overflow: hidden;
    text-align: center;
    padding-bottom: 0px;
}

.Link {
    padding-top: 20px;
    padding-bottom: 50px;
    background-color: white;
    font-size: 20px;
    font-family: Helvetica;
    font-weight: bold;
}

.ContactSection {
    overflow: hidden;
    text-align: center;

}

.ContactImage {
    flex: 1;
    margin-top: 10px;
    padding-right: 10px;
}

.ContactLink {
    flex: 5;
    margin-top: 10px;

}

.ContactFlexContainer {
    /* background-color: purple; */
    display: flex;
    height: auto;
    flex-direction: row;
    max-width: 75%;
    align-items: center;
    margin: 0 auto;
}

.ContactSection a {
    text-decoration: none;
    color: var(--light-green)
}
.ContactSection a:hover {
    text-decoration: none;
    color: var(--dark-blue)
}

.Section a {
    text-decoration: none;
    color: var(--light-green)
}
.Section a:hover {
    text-decoration: none;
    color: var(--dark-blue)
}

.Image {
    flex: 1;
    box-sizing: border-box;


}

.Image img{
    /* background-color: blue; */
    max-width: 100%;
    max-height: 300px;
    border-radius: 20px;
}

.Explanation {
    /* background-color: green; */
    font-size: 20px;
    font-family: Helvetica;
    max-width: 100%;
    text-align: left;
    flex: 2;
    box-sizing: border-box;
}

.Title {
    align: center;
    color: var(--dark-green);
    font-size: 20px;
    font-family: Helvetica;
    font-weight: bold;
}

.Explanation p {
    text-align: left;
    text-indent: 40px;
}

.FlexContainer {
    /* background-color: purple; */
    display: flex;
    height: auto;
    flex-direction: column;
    max-width: 80%;
    align-items: center;

    margin: 0 auto;
}


@media only screen and (min-width: 800px) {

    .Section {
        overflow: hidden;
        text-align: center;
    }
    
    .Image {
        flex: 1;
    }
    
    .Image img{
        /* background-color: blue; */
        max-width:100%;
        height:auto;
        border-radius: 15px;
    }
    
    .Explanation {
        /* background-color: green; */
        font-size: 20px;
        font-family: Helvetica;
        flex: 2;
        padding-left: 40px;
    }
    
    .Title {
        align: center;
        color: var(--dark-green);
        font-size: 28px;
        font-family: Helvetica;
        font-weight: bold;
    }
    
    .FlexContainer {
        /* background-color: purple; */
        display: flex;
        height: auto;
        flex-direction: row;
        max-width: 80%;
        align-items: stretch;
        margin: 0 auto;
    } 
    
    .ContactSection {
        overflow: hidden;
        text-align: center;
    
    }
    
    .ContactImage {
        flex: 1;
        margin-top: 10px;
        padding-right: 10px;
    }
    
    .ContactLink {
        flex: 1;
        margin-top: 10px;
    }
    
    .ContactFlexContainer {
        /* background-color: purple; */
        display: flex;
        height: auto;
        flex-direction: row;
        max-width: 50%;
        align-items: center;
        margin: 0 auto;
    }

}