.Content {
    background-color: var(--gray);
    height: auto;
    position: relative;
    top: 0;
    padding: 0px;
}

.Spacer {
    background-color: var(--dark-green);
    height: 3px;
    margin: 50px;

}

