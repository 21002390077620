.Page {
    margin: 0;
    padding: 0;
}

.PageTitle{
    color: var(--dark-green);
    font-size: 40px;
    font-family: Helvetica;
    font-weight: bold;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0px 0px 50px 0px;
    background-color: var(--light-blue);
    /* border-bottom: 5px solid black; */
}

.TitleUnderline {
    width: 40%;
    height: 4px;
    margin: auto;
    border-radius: 10px;
    margin-bottom: 60px;
    background-color: var(--dark-blue);
  }
